<template>
    <div>
        <div class="row row-eq-height">
            <div class="col-lg-4 col-md-6 p-2 d-flex" :key="element.id" v-for="element in elements"
                v-bind:class="{ 'low-balance' : ($store.getters.lowBalanceUserId.includes(element.id)) }">
                <div class="card flex-fill w-100 mb-0 p-2">
                    <div class="d-flex">
                        <div class="flex-fill ml-2">
                            <div class="d-flex align-content-center align-items-center justify-content-center"
                                    style="line-height: 1.2rem;">
                                <div class="flex-fill d-flex flex-column align-content-center justify-content-center position-relative">
                                    <div>
                                        <a href="javascript:void(0);"  @click="viewDetailInfo(element)">
                                            <strong v-bind:class="{ 'text-danger' : ($store.getters.lowBalanceUserId.includes(element.id)) }">
                                                <span>{{element.fullName}}</span>
                                            </strong>
                                            &nbsp;<i class="text-muted">(<strong v-bind:class="{ 'text-danger' : ($store.getters.lowBalanceUserId.includes(element.id)) }">{{element.userName}}</strong>)</i>
                                        </a>
                                                
                                        <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                                data-toggle="tooltip" title="Click copy username to clipboard"
                                                @click="copyToClipboard('#userCopy', element.userName)"
                                                style="font-size: 10px; position: relative; top: -2px;">
                                            <i class="fa fa-copy text-muted"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <small v-if="element.appCode" :class="`label ${$appCode[element.appCode]}`">
                                            {{element.appCode}}
                                        </small>
                                        <!--<span class="ml-1">
                                            &nbsp;<small class="label label-success">web app</small>
                                        </span>&nbsp;&nbsp;-->
                                        <small class="text-muted mb-0 ml-1" >
                                            <img :src="`/img/countries/${element.region}.png`" 
                                                    class="position-relative mr-0"  onerror="this.style = 'display: none;';"
                                                    style="top: -1px;"/>&nbsp;
                                            <span v-if="Object.keys($countryList).includes(element.region)">{{$countryList[element.region]}}</span>
                                            <span v-else>{{element.region}}</span>
                                        </small>
                                    </div>

                                    <div>
                                        <small><i class="text-info far fa-calendar-plus"></i> <i>Created <strong>{{element.dateCreatedAt}}</strong></i></small>
                                        <small v-if="element.dateLastActive">
                                            &nbsp;&nbsp;<i class="fa fa-heart text-danger"></i> Actived on <i>{{element.dateLastActive}}</i>
                                        </small>
                                    </div>
                                    <div>
                                        <div class="text-nowrap">
                                            <small class="size-90pc"><i class="fa fa-calendar text-info"></i>  <i>Born <strong>{{element.dateBirthday}}</strong></i></small>
                                        </div>
                                        <div class="text-nowrap">
                                            <a class="text-primary" :href="`mailto:${element.email}`">
                                                <small class="size-90pc"><i class="far fa-envelope"></i> {{element.email}}</small>
                                            </a>
                                            <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                                    data-toggle="tooltip" title="Click copy email to clipboard"
                                                    @click="copyToClipboard('#userCopy', element.email)"
                                                    style="font-size: 10px; position: relative; top: -2px;">
                                                <i class="fa fa-copy text-muted"></i>
                                            </button>
                                        </div>
                                        <div v-if="element.phone">
                                            <a class="text-info" :href="`tel:${element.phone}`">
                                                <small class="size-90pc"><i class="fas fa-phone-square"></i> {{element.phone}}</small>
                                            </a>
                                            <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                                    data-toggle="tooltip" title="Click copy phone to clipboard"
                                                    @click="copyToClipboard('#userCopy', element.phone)"
                                                    style="font-size: 10px; position: relative; top: -2px;">
                                                <i class="fa fa-copy text-muted"></i>
                                            </button>
                                        </div>
                                    </div>
                                                    
                                    <div :id="`note_${element.id}`"
                                            class="d-flex align-content-center mt-2 align-items-center mr-2">
                                        <div class="alert bg-light cursor-pointer user-note-content p-1 mb-0"
                                                v-if="element.noteContent" @click="performAction('viewDetailNote', element)">
                                            <div v-html="element.noteContent"  data-toggle="tooltip" title="Click to view more"
                                                    style="max-height: 2.3rem;overflow: hidden;white-space: normal !important;font-size: 0.8rem;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                
                            </div>
                        </div>
                        <div class="profile-image-ctn position-relative text-right p-2 pr-0">
                            <div>
                                <a href="javascript:void(0);"  @click="viewDetailInfo(element)">
                                    <div v-if="element.profilePhoto !== null && element.profilePhoto !== ''">
                                        <div v-if="!element.profilePhoto.includes('http')">
                                            <img  v-if="!element.profilePhoto.includes('data:image')"
                                                    class="profile-image rounded" 
                                                    :src="`data:image/jpeg;base64,${element.profilePhoto}`"
                                                    onerror="this.src='/img/avatars/male.png'" />
                                            <img  v-else
                                                    class="profile-image rounded" :src="element.profilePhoto"
                                                    onerror="this.src='/img/avatars/male.png'" />
                                        </div>
                                        <img v-else class="profile-image rounded" :src="element.profilePhoto" 
                                                    onerror="this.src='/img/avatars/male.png'"/>
                                    </div>
                                    <div v-else>                                                
                                        <img  class="profile-image" src="/img/avatars/male.png" />
                                    </div>
                                </a>
                            </div>
                            <div class="mt-1" style="line-height: 0.9rem;">
                                <small>Last activity <br/>{{element.friendlyUpdatedAt}}</small>
                            </div>
                            <div class="mt-1 text-nowrap">
                                <button type="button" class="btn bg-gradient-danger btn-xs"
                                        @click="performAction('removeUser', element)">
                                    Remove this user
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import baseComponent from "@/scripts/baseComponent";
    export default {
        extends: baseComponent,
        props: {
            elements: {
                type: Array,
                default() { return []; },
            }
        },
        methods: {
            performAction(actionName, data) {
                this.$emit("actions", { actionName: actionName, actionData: data });
            }
        }
    }
</script>